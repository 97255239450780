import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion as m } from 'framer-motion';
import ScrollToTop from '../../components/ScrollToTop';
import PageHeaderImage from '../../images/pageheader.jpg';
import PageHeader from '../../components/PageHeader';
import {MdEmail} from 'react-icons/md';
import {BsMessenger} from 'react-icons/bs';
import {IoLogoWhatsapp} from 'react-icons/io';
// import { useRef } from 'react';
// import emailjs from '@emailjs/browser';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './contact.css';

const Contact = () => {
    return (
        <m.div
            initial={{x: "-100%"}}
            animate={{x: "-0%"}}
            transition={{ duration: 0.9, delay:0.7}}
            exit={{opacity: 1}}
        >
            <ScrollToTop />
            <PageHeader title="Contact" image={PageHeaderImage}>
                <p>
                    <Link to="/">Home</Link> / Contact
                </p>
            </PageHeader>
            <section className="contact">
                <div className="container contact_container">
                    <div className="form_wrapper">
                        <h4>Get In Touch</h4>
                        {/* ref={form} onSubmit={sendEmail} */}
                        <form>
                            <input type="text" name='name' placeholder='Your full name' required/>     
                            <input type="text" name='email' placeholder='Your Email' required/>
                            <textarea name="message" id="" cols="30" rows="10" placeholder='Your Message' required></textarea>
                            <button className="btn lg contact_form_btn">Send Message</button>              
                        </form>
                    </div>
                    <div className="contact_wrapper">
                        <div className="contact_wrapper_contents">
                            <a href="mailto:info@shisanyaadvocates.co.ke" target='_blank' rel='noreferrer noopener'>
                                <MdEmail/>
                            </a>
                            <p>Email Us</p>
                        </div>
                        <div className="contact_wrapper_contents">
                            <a href="https://m.me/" target='_blank' rel='noreferrer noopener'>
                                <BsMessenger/>
                            </a>
                            <p>Messenger</p>
                        </div>
                        <div className="contact_wrapper_contents">
                            <a href="https://wa.me/+254711247436" target='_blank' rel='noreferrer noopener'>
                                <IoLogoWhatsapp/>
                            </a>
                            <p>WhatsApp Us</p>
                        </div>
                    <div/>
                    </div>
                    <div className="contact_map_container">
                        <h4>Location</h4>
                        <iframe data-aos='fade-up' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.799096707243!2d36.796230674080654!3d-1.2951052356339643!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f10be1cd0bc33%3A0x6733974af7e19a5c!2sNyaku%20House!5e0!3m2!1sen!2sjp!4v1707192276456!5m2!1sen!2sjp" width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="Location"></iframe>
                    </div>
                </div>
            </section>
        </m.div>
    )
    }

export default Contact