import {motion as m} from 'framer-motion';
import ScrollToTop from '../../components/ScrollToTop';
import {useEffect} from 'react';
import PageHeaderImage from '../../images/pageheader.jpg';
import PageHeader from '../../components/PageHeader';
import {Link} from 'react-router-dom';
import WhyUs from '../../components/WhyUs';
import ContactCta from '../../components/ContactCta';
import FAQS from '../../components/FAQS';
import './legal-advisory-consultancy-department.css';

const LegalAdvisoryConsultancyDepartment = () => {
    return (
        <m.div 
            initial={{x: "-100%"}}
            animate={{x: "-0%"}}
            transition={{ duration: 0.9, delay:0.7}}
            exit={{opacity: 1}}
        >
            <ScrollToTop />
            <PageHeader title="Litigation and Dispute Resolution" image={PageHeaderImage}>
                <p>
                    <Link to="/">Home</Link> / <Link to="/services">Depertments</Link> / Legal Advisory and Consultancy Department
                </p>
            </PageHeader>
            <section className='services'>

                <div className="commercial-pest-control">
                    <div className="container commercial-pest-control-contcontent-container">
                        <div className="commercial-pest-control-content">
                            <h1>
                                Legal Advisory and Consultancy Department
                            </h1>
                            <br />
                            <p>
                                The firm has a host of consultants who provide advisory and consultancy services in various fields including but not limited to the following:
                            </p>

                            <ul className='legal-department-list'>
                                <li>• Legal auditing and compliance</li>
                                <li>• Financial Services & Capital Markets with specific advice on the ownership structure, relevant regulatory approvals, corporate governance, and tax</li>
                                <li>• Public Procurement</li>
                                <li>• Governance issues on The Constitution of Kenya, 2010</li>
                                <li>• Electoral Processes and electoral disputes</li>
                                <li>• Devolution</li>
                                <li>• Ethics and Anti-Corruption</li>
                                <li>• Insurance</li>
                                <li>• Intellectual Property Rights</li>
                                <li>• Human Rights</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="container commercial-pest-control-saftey-cont">
                    <ContactCta />
                </div>
            </section>
        </m.div>
    )
}

export default LegalAdvisoryConsultancyDepartment