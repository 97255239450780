import {motion as m} from 'framer-motion';
import ScrollToTop from '../../components/ScrollToTop';
import {useEffect} from 'react';
import PageHeaderImage from '../../images/pageheader.jpg';
import PageHeader from '../../components/PageHeader';
import {Link} from 'react-router-dom';
import WhyUs from '../../components/WhyUs';
import ContactCta from '../../components/ContactCta';
import FAQS from '../../components/FAQS';
import './judicialreviewconstitutionallaw.css';

const JudicialReviewConstitutionalLaw = () => {
    return (
        <m.div 
            initial={{x: "-100%"}}
            animate={{x: "-0%"}}
            transition={{ duration: 0.9, delay:0.7}}
            exit={{opacity: 1}}
        >
            <ScrollToTop />
            <PageHeader title="Judicial Review and Constitutional Law" image={PageHeaderImage}>
                <p>
                    <Link to="/">Home</Link> / <Link to="/services">Practice Areas</Link> / Judicial Review and Constitutional Law
                </p>
            </PageHeader>
            <section className='services'>

                <div className="consultancy-training-services">
                    <div className="container consultancy-training-content-container">
                        <div className="consultancy-training-content">
                            <h1>
                                Judicial Review and Constitutional Law
                            </h1>
                            <br />
                            <p>
                                Our team has excellent expertise in Judicial Review and Constitutional law in line with the progressive nature of the Constitution of Kenya 2010. Our team acts over a whole range of Constitutional and Judicial review practice areas in Courts and Tribunals in Kenya. We are experts in the law governing the relationship between persons (natural and legal) and the State. For Claimants/Petitioners, we are equally at home representing commercial clients challenging complex regulatory or governmental decisions such as acting for individuals or NGOs in ground-breaking human rights and constitutional challenges.
                                We also act for regulators and other public bodies as Respondents/Defendants. As such, our team is well versed with and or skilled in the application of Constitutional and Administrative law.
                                Some of the services we render in this area include:
                            </p>
                            <br/>
                            <ul>
                                <li>
                                •	Legislative, regulation and policy drafting
                                </li>
                                <li>
                                •	Litigation arising out of the exercise of administrative powers by authorities.
                                </li>
                                <li>
                                •	Public interest litigation.
                                </li>
                                <li>
                                •	Constitutional litigation based on violation of rights, seeking interpretations on specific Constitutional provisions, challenging certain Statutory provisions etc. 
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="container consultancy-training-saftey-cont">
                    <ContactCta />
                </div>
            </section>
        </m.div>
    )
}

export default JudicialReviewConstitutionalLaw