import {motion as m} from 'framer-motion';
import ScrollToTop from '../../components/ScrollToTop';
import PageHeaderImage from '../../images/pageheader.jpg';
import PageHeader from '../../components/PageHeader';
import {Link} from 'react-router-dom';
import AboutSection from '../../components/AboutSection';
import {FaLightbulb} from 'react-icons/fa';
import {BsBinocularsFill} from 'react-icons/bs';
import WhyUs from '../../components/WhyUs';
import Testimonial from '../../components/Testimonial';
import './about.css';

const About = () => {

    return (
        <m.div
            initial={{x: "-100%"}}
            animate={{x: "-0%"}}
            transition={{ duration: 0.9, delay:0.7}}
            exit={{opacity: 1}}
        >
            <ScrollToTop />
            <PageHeader title="About" image={PageHeaderImage}>
                <p>
                    <Link to="/">Home</Link> / About Us
                </p>
            </PageHeader>
            <section className='about'>
                <div className="container about_container">
                    <div className="container about about-pestex">
                        <div className="about-content">
                            <h1>Who We Are</h1>
                            <p>
                                Established in 2020, Shisanya and Company advocates is Africa's fast-growing premier law firm located in Nairobi, Kenya. The firm prides itself in being a trusted legal partner properly placed to meet clients' needs and offer timely solutions to legal problems.
                            </p>
                            <p>
                                Since establishment, the firm has maintained commitment to placing client needs above all else and as such it boasts of a strong reputation in the legal profession for its excellent and exemplary track record. 
                                The firm also boasts of a dynamic youthful experts whose experience cuts across all areas of law both locally, regionally and internationally. 
                            </p>
                            <p>
                                Further, the team has a keen and deep understanding of the legal, commercial, and regulatory environment in Kenya and the East African Region.  The team's expertise has to that end earned the confidence of an impressive list of clients, both domestic, regional and international ranging from Non-Governmental Organizations, Corporations and natural persons to name but a few. Our methodology is client-centered, geared towards offering solution-oriented service to our clients. 
                                We are therefore the go to firm whenever a legal, commercial and or regulatory need arises. 
                            </p>
                            <br/>
                            <h2 className='mission-value'>Our Mission</h2>
                            <p>
                                To be the leading global firm in fostering amicable resolution of disputes as well as ensuring our clients’ legal compliance with relevant regulations, laws and statutes. 
                            </p>
                            <br />
                            <h2 className='mission-value'>Our Vision</h2>
                            <p>
                                To be the go-to law firm that provides its clients with ethically sound legal counsel in a timely and efficient manner by anchoring our practice of law on four key pillars of Client focus, accountability, transparency and effectiveness.
                            </p>
                        </div>
                    </div>
                    <WhyUs />
                    <Testimonial />
                </div>
            </section>
        </m.div>
    )
}

export default About