import {motion as m} from 'framer-motion';
import ScrollToTop from '../../components/ScrollToTop';
import {useEffect} from 'react';
import PageHeaderImage from '../../images/pageheader.jpg';
import PageHeader from '../../components/PageHeader';
import {Link} from 'react-router-dom';
import WhyUs from '../../components/WhyUs';
import ContactCta from '../../components/ContactCta';
import FAQS from '../../components/FAQS';
import './litigationDisputeResolution.css';

const LitigationDisputeResolution = () => {
    return (
        <m.div 
            initial={{x: "-100%"}}
            animate={{x: "-0%"}}
            transition={{ duration: 0.9, delay:0.7}}
            exit={{opacity: 1}}
        >
            <ScrollToTop />
            <PageHeader title="Litigation and Dispute Resolution" image={PageHeaderImage}>
                <p>
                    <Link to="/">Home</Link> / <Link to="/services">Practice Areas</Link> / Litigation and Dispute Resolution
                </p>
            </PageHeader>
            <section className='services'>

                <div className="commercial-pest-control">
                    <div className="container commercial-pest-control-contcontent-container">
                        <div className="commercial-pest-control-content">
                            <h1>
                                Litigation and Dispute Resolution
                            </h1>
                            <br />
                            <p>
                                We are involved in day to day litigation before all courts and tribunals in Kenya.  We have a dedicated team that handles various contentious disputes in the Supreme Court, Court of Appeal, the High Court, Subordinate Courts, the Tribunals, Quasi-Judicial Tribunals and other fora where there is need for advocacy and litigation on behalf of our clients with a high success rate in all contentious matters we handle.
                                The range of litigation services we offer include:
                            </p>
                            <br/>
                            <ol>
                                <li>
                                    <p>
                                    •	Land disputes
                                    </p>
                                </li>
                                <li>
                                    <p>
                                    •	Employment and Labour law disputes
                                    </p>
                                </li>
                                <li>
                                    <p>
                                    •	Banking and finance disputes,
                                    </p>
                                </li>
                                <li>
                                    <p>
                                    •	Intellectual property actions
                                    </p>
                                </li>
                                <li>
                                    <p>
                                    •	Contract and Property disputes
                                    </p>
                                </li>
                                <li>
                                    <p>
                                    •	Tort Claims
                                    </p>
                                </li>
                                <li>
                                    <p>
                                    •	Professional negligence claims
                                    </p>
                                </li>
                                <li>
                                    <p>
                                    •	Insurance claims
                                    </p>
                                </li>
                                <li>
                                    <p>
                                    •	Tax law other general areas
                                    </p>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>

                <div className="container commercial-pest-control-saftey-cont">
                    <ContactCta />
                </div>
            </section>
        </m.div>
    )
}

export default LitigationDisputeResolution