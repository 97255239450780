import {motion as m} from 'framer-motion';
import ScrollToTop from '../../components/ScrollToTop';
import {useEffect} from 'react';
import PageHeaderImage from '../../images/pageheader.jpg';
import PageHeader from '../../components/PageHeader';
import {Link} from 'react-router-dom';
// import HomeServices from '../../components/HomeServices';
import WhyUs from '../../components/WhyUs';
import ContactCta from '../../components/ContactCta';
import FAQS from '../../components/FAQS';
import './commercialcorporatelaw.css';

const CommercialCorporateLaw = () => {
    return (
        <m.div 
            initial={{x: "-100%"}}
            animate={{x: "-0%"}}
            transition={{ duration: 0.9, delay:0.7}}
            exit={{opacity: 1}}
        >
            <ScrollToTop />
            <PageHeader title="Commercial and Corporate Law" image={PageHeaderImage}>
                <p>
                    <Link to="/">Home</Link> / <Link to="/services">Practice Areas</Link> / Commercial and Corporate Law
                </p>
            </PageHeader>
            <section className='services'>

                <div className="bird-control-container container">
                    <div className="bird-control-content-container">
                        <div className="bird-control-content">
                            <h1>
                                Commercial and Corporate Law
                            </h1>
                            <br />
                            <p>
                                We provide a comprehensive range of commercial and corporate legal services to our clients. Our team is equipped with extensive experience in representing various corporate and individual clients including top tier firms in the finance industry. The firm is also equipped to advise and offer legal services to banks and micro-finance institutions among other financial entities. Our range of services in this area include:.
                            </p>
                            <br />
                            <ul>
                                <li>
                                •	Advising on legal framework for proposed business entities as well as preferred structure of investment.
                                </li>
                                <li>
                                •	Advising on compliance with perfection and registration of charges, debentures, movable property security rights, deeds of rental assignment, lender’s agreements, deeds of guarantee and related agreements.
                                </li>
                                <li>
                                •	Advising on the Kenyan regulatory regime for various corporate and commercial transactions.
                                </li>
                                <li>
                                •	Advising on and obtaining government approvals, permits and licences for and on behalf of the clients.
                                </li>
                                <li>
                                •	Drafting contracts
                                </li>
                                <li>
                                •	Corporate litigation
                                </li>
                                <li>
                                •	Incorporation of companies
                                </li>
                                <li>
                                •	Insolvency and Liquidation
                                </li>
                                <li>
                                •	Intellectual Property (IP)
                                </li>
                                <li>
                                •	Asset acquisitions and disposals
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="container bird-control-saftey-cont">
                    <ContactCta />
                </div>
            </section>
        </m.div>
    )
}

export default CommercialCorporateLaw