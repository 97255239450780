import {motion as m} from 'framer-motion';
import ScrollToTop from '../../components/ScrollToTop';
import PageHeaderImage from '../../images/pageheader.jpg';
import PageHeader from '../../components/PageHeader';
import {Link} from 'react-router-dom';
import WhyUs from '../../components/WhyUs';
import ContactCta from '../../components/ContactCta';
import FAQS from '../../components/FAQS';
import './criminallaw.css';

const CriminalLaw = () => {
    return (
        <m.div 
            initial={{x: "-100%"}}
            animate={{x: "-0%"}}
            transition={{ duration: 0.9, delay:0.7}}
            exit={{opacity: 1}}
        >
            <ScrollToTop />
            <PageHeader title="Criminal law" image={PageHeaderImage}>
                <p>
                    <Link to="/">Home</Link> / <Link to="/services">Practice Ares</Link> / Criminal law
                </p>
            </PageHeader>
            <section className='services'>

                <div className="residential-pest-control-services">
                    <div className="container residential-pest-control-content-container">
                        <div className="residential-pest-control-content">
                            <h1>
                                Criminal Law
                            </h1>
                            <br />
                            <p>
                                The firm has vast experience in criminal law matters. We zealously represent our clients in criminal law cases. 
                                Our range of services in criminal law extend to:
                            </p>
                            <br />
                            <ul>
                                <li>
                                •	Defending accused persons in all manner of criminal case
                                </li>
                                <li>
                                •	Advising on strategy in criminal law cases.
                                </li>
                                <li>
                                •	Defending the Constitutional rights of accused persons by way of Judicial Review proceedings aimed at quashing unfair criminal processes. 
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="container residential-pest-control-saftey-cont">
                    <ContactCta />
                </div>
            </section>
        </m.div>
    )
}

export default CriminalLaw