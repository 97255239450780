import React from 'react';
import {consultantsCarouselData} from '../data';
import Card from '../UI/Card';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './consultantcarousel.css';

const consultantCarousel = () => {

    const settings = {
        dots: true,
        autoplay: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className='team-cont'>
            <div className="cards-cont">
            <>
                {
                    consultantsCarouselData.map(({id, img, name, description})=>{
                        return <Card key={id} className="consultant-card">
                            <div className="consultant-card-img">
                                <img src={img} alt="consultant member image" />
                            </div>
                            <div className="consultant-member-details">
                                <h4>{name}</h4>
                                <p>{description}</p>
                            </div>
                        </Card>
                    })
                }
            </>
            </div>
        </div>
    )
}

export default consultantCarousel