import {motion as m} from 'framer-motion';
import ScrollToTop from '../../components/ScrollToTop';
import {useEffect} from 'react';
import PageHeaderImage from '../../images/pageheader.jpg';
import PageHeader from '../../components/PageHeader';
import {Link} from 'react-router-dom';
import WhyUs from '../../components/WhyUs';
import ContactCta from '../../components/ContactCta';
import FAQS from '../../components/FAQS';
import './conveyancing-real-estate-department.css';

const ConveyancingRealEstateDepartment = () => {
    return (
        <m.div 
            initial={{x: "-100%"}}
            animate={{x: "-0%"}}
            transition={{ duration: 0.9, delay:0.7}}
            exit={{opacity: 1}}
        >
            <ScrollToTop />
            <PageHeader title="Litigation and Dispute Resolution" image={PageHeaderImage}>
                <p>
                    <Link to="/">Home</Link> / <Link to="/services">Depertments</Link> / Conveyancing and Real Estate Department
                </p>
            </PageHeader>
            <section className='services'>

                <div className="commercial-pest-control">
                    <div className="container commercial-pest-control-contcontent-container">
                        <div className="commercial-pest-control-content">
                            <h1>
                                Conveyancing and Real Estate Department
                            </h1>
                            <br />
                                <p>
                                    This department primarily deals with land, developments thereon and all other matters related thereto. This department provides real estate services to clients including obtaining titles, conducting searches at the relevant registries, preparing high level and complex property schemes, advising and effecting change of user, negotiating and creation of all securities and conducting security due diligence. 
                                </p>

                                <p>
                                    So far, we have satisfactorily and successfully handled a high number of conveyancing transactions on behalf of our clients. 
                                </p>
                        </div>
                    </div>
                </div>

                <div className="container commercial-pest-control-saftey-cont">
                    <ContactCta />
                </div>
            </section>
        </m.div>
    )
}

export default ConveyancingRealEstateDepartment