import React from 'react';
import {homeCarouselServices} from '../data';
import Card from '../UI/Card';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import '../pages/home/home.css';

const HomeCarouselServices = () => {

    const settings = {
        dots: true,
        autoplay: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className='services-carousel-sect'>
            <div className="cards-cont">
            <Slider {...settings}>
                {
                    homeCarouselServices.map(({id, img, name, description, path})=>{
                        return <Card key={id} className="home-services-card">
                            <div className="home-services-img">
                                <img src={img} alt="photography services images" />
                            </div>
                            <div className="home-services-cont-text">
                                <h4>{name}</h4>
                                <p>{description}</p>
                                <Link to={path} className='btn sm contact-cta-btn'>Read More</Link>
                            </div>
                        </Card>
                    })
                }
            </Slider>
            </div>
        </div>
    )
}

export default HomeCarouselServices