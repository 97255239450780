import {motion as m} from 'framer-motion';
import ScrollToTop from '../../components/ScrollToTop';
import {useEffect} from 'react';
import PageHeaderImage from '../../images/pageheader.jpg';
import PageHeader from '../../components/PageHeader';
import {Link} from 'react-router-dom';
import WhyUs from '../../components/WhyUs';
import ContactCta from '../../components/ContactCta';
import FAQS from '../../components/FAQS';
import './alternativedisputeresolution.css';

const AlternativeDisputeResolution = () => {
    return (
        <m.div 
            initial={{x: "-100%"}}
            animate={{x: "-0%"}}
            transition={{ duration: 0.9, delay:0.7}}
            exit={{opacity: 1}}
        >
            <ScrollToTop />
            <PageHeader title="Alternative Dispute Resolution" image={PageHeaderImage}>
                <p>
                    <Link to="/">Home</Link> / <Link to="/services">Practice Areas</Link> / Alternative Dispute Resolution 
                </p>
            </PageHeader>
            <section className='services'>

                <div className="fumigation-services">
                    <div className="container fumigation-services-content-container">
                        <div className="fumigation-services-content">
                            <h1>
                                Alternative Dispute Resolution
                            </h1>
                            <br />
                            <p>
                                We have a skilled team with professional training on Alternative Dispute Resolution. We represent clients in all avenues of Alternative Dispute Resolution including Arbitration, mediation as well as negotiation. Our team has been able to ensure that matters are handled with the utmost sensitivity and pragmatism towards achieving effective results that allow commercial and or family relationships to remain intact.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="container fumigation-services-saftey-cont">
                    <ContactCta />
                </div>
            </section>
        </m.div>
    )
}

export default AlternativeDisputeResolution