import { Link } from "react-router-dom";
import { useEffect } from "react";
import { FaAward } from "react-icons/fa";
import { GiIonicColumn } from "react-icons/gi";
import { MdOutlineHandshake } from "react-icons/md";
import '../pages/home/home.css';

const PracticeAreaIntro = () => {

    return (
        <div className="cta">
            <div className="container cta-container cta_cont_practice_area">
                <div className="cta-text-l">
                    <h2>Practice Areas</h2> 
                </div>
                <div className="cta-text-r">
                    <div className="value value_practice_area">
                        <p>Shisanya and Company advocates is Africa's fast-growing premier law firm located in Nairobi, Kenya. The firm prides itself in being a trusted legal partner properly placed to meet clients' needs and offer timely solutions to legal problems. Since establishment, the firm has maintained commitment to placing client needs above all else and as such it boasts of a strong reputation in the legal profession for its excellent and exemplary track record.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PracticeAreaIntro