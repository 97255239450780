import { useEffect } from "react";
import { Link } from "react-router-dom";
import {AiFillFacebook} from 'react-icons/ai';
import {AiFillInstagram} from 'react-icons/ai';
import {AiFillTwitterSquare} from 'react-icons/ai';
import {FaWhatsappSquare} from 'react-icons/fa';
import YearDateUpdate from "./YearDateUpdate";
import Logo from "../images/logo.jpeg";
import './footer.css';

const Footer = () => {
    return (
        <div className="footer">
            <div className="container footer_container">
                <div className="footer_details_cont">
                    <div className="footer_detail footer_detail_one">
                        <div className="footer_logo">
                            <Link to="/home">
                                {/* <img src={Logo} className="footer-logo"/> */}
                            </Link>
                        </div>
                        <div className="footer_about">
                            <p>
                                Established in 2020, Shisanya and Company advocates is Africa’s fast-growing premier law firm located in Nairobi, Kenya. The firm prides itself in being a trusted legal partner properly placed to meet clients’ needs and offer timely solutions to legal problems. 
                            </p>
                        </div>
                    </div>
                    <div className="footer_detail footer_detail_two">
                        <h3>Quick Links</h3>
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/about">About</Link></li>
                            <li><Link to="/services">Practice Areas</Link></li>
                            <li><Link to="/contact">Contact</Link></li>
                        </ul>
                    </div>
                    <div className="footer_detail footer_detail_two">
                        <h3>Location</h3>
                        <ul>
                            <li>Nyaku House, 2nd Floor - Room 15B</li>
                            <li>Argwings Kodhek Road</li>
                            <li>Nairobi, Kenya.</li>
                        </ul>
                    </div>
                    <div className="footer_detail footer_detail_three">
                        <h3>Get In Touch</h3>
                        <ul>
                            <li>
                                <p>P.O Box 30414 - 00100 Nairobi, Kenya</p>
                            </li>
                            <li>
                                <p>
                                    info@shisanyaadvocates.co.ke
                                </p>
                            </li>
                            <li>
                                <p>
                                    (+254) 711-247-436
                                </p>
                            </li>
                            <br/>
                            <li>
                                <h3>Business Hours</h3>
                            </li>
                
                            <li>Monday - Friday: 8:00 am - 5:00 pm</li>
                        </ul>
                    </div>
                </div>
                <div className="footer_copyright_cont">
                    <div className="copyright">
                        <p>©<YearDateUpdate/> Shisanya Advocates. All Rights Reserved.</p>
                    </div>
                    <div className="footer-designed-by">
                        <p> Designed by <Link to="https://mansionarts.net/" target="_blank">Mansion Arts Ltd.</Link></p>   
                    </div>
                    <div className="footer_socials">
                        <ul>
                            <li><Link to="#" target="_blank"><AiFillFacebook/></Link></li>
                            <li><Link to="#" target="_blank"><AiFillInstagram/></Link></li>
                            <li><Link to="#" target="_blank"><AiFillTwitterSquare/></Link></li>
                            <li><Link to="#" target="_blank"><FaWhatsappSquare/></Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer