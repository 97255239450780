import { useState} from 'react';
import {Link} from 'react-router-dom';
import { depertmentslinks } from '../data';
import './dropdowntwo.css';


const DropdownTwo = () => {

    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);

    return (
        <>
            <ul onClick={handleClick}
                className={click ? 'dropdown-menu-two clicked' : 'dropdown-menu-two'}
            >
                {depertmentslinks.map((item, index) => {
                    return (
                        <li key={index}>
                            <Link className={item.cName} to={item.path} onClick={() => setClick(false)}>
                                {item.name}
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </>
    );
};

export default DropdownTwo;