import {motion as m} from 'framer-motion';
import ScrollToTop from '../../components/ScrollToTop';
import {useEffect} from 'react';
import PageHeaderImage from '../../images/pageheader.jpg';
import PageHeader from '../../components/PageHeader';
import {Link} from 'react-router-dom';
import { ImQuotesRight } from "react-icons/im";
import './practice-areas.css';

const PracticeAreas = () => {
    return (
        <m.div 
            initial={{x: "-100%"}}
            animate={{x: "-0%"}}
            transition={{ duration: 0.9, delay:0.7}}
            exit={{opacity: 1}}
        >
            <ScrollToTop />
            <PageHeader title="Practice Areas" image={PageHeaderImage}>
                <p>
                    <Link to="/">Home</Link> / Practice Areas
                </p>
            </PageHeader>
            <section className='services'>


                <div className="services-content">
                    <div className="services-content-container">
                        <div className="services-content">
                            <div className="services-content-header">
                                <p>
                                    <ImQuotesRight className='quotes_services_intro'/>
                                    <br/>
                                        Our law firm prides itself in being a trusted legal partner properly placed to meet client's needs and offer timely solutions to legal problems. Since establishment, the firm has maintained it's commitment to placing client needs above all else and as such it boasts of a strong reputation in the legal profession for its excellent and exemplary track record.
                                </p>
                            </div>
                            <div className="sevices-main-content-cont">
                                {/* services one */}
                                <div className="sevices-content-examples">
                                    <div className="services-content-sample services-content-sample-1 residential-pest-control-services-content">
                                        <Link to='/litigation-dispute-resolution'>
                                            <h2>
                                                Litigation and Dispute Resolution
                                            </h2>
                                            <p>
                                                We are involved in day to day litigation before all courts and tribunals in Kenya.  We have a dedicated team that handles various contentious disputes in the Supreme Court, Court of Appeal, the High Court, Subordinate Courts, the Tribunals, Quasi-Judicial Tribunals and other fora.
                                            </p>
                                        </Link>
                                    </div>
                                    <div className="services-content-sample services-content-sample-2 commercial-pest-control-services-content">
                                        <Link to='/family-law-succession'>
                                            <h2>
                                                Family law and Succession 
                                            </h2>
                                            <p>
                                                We advise a diverse number of our clients on issues of family, inheritance and succession.  We deliver hands-on and personal guidance on family and succession issues. We also handle court succession disputes whenever any of them arise.
                                            </p>
                                        </Link>
                                    </div>
                                    <div className="services-content-sample services-content-sample-3">
                                        <Link to='/commercial-corporate-law'>
                                            <h2>
                                                Commercial and Corporate Law 
                                            </h2>
                                            <p>
                                                We provide a comprehensive range of commercial and corporate legal services to our clients. Our team is equipped with extensive experience in representing various corporate and individual clients including top tier firms in the finance industry. 
                                            </p>
                                        </Link>
                                    </div>
                                    <div className="services-content-sample services-content-sample-4">
                                        <Link to='/criminal-law'>
                                            <h2>
                                                Criminal law 
                                            </h2>
                                            <p>
                                                The firm has vast experience in criminal law matters. We zealously represent our clients in criminal law cases. 
                                            </p>
                                        </Link>
                                    </div>
                                </div>
                                {/* services two */}
                                <div className="sevices-content-examples-two">
                                    <div className="services-content-sample services-content-sample-5 residential-pest-control-services-content">
                                        <Link to='/judicial-review-constitutional-law'>
                                            <h2>
                                                Judicial Review and Constitutional Law 
                                            </h2>
                                            <p>
                                                Our team has excellent expertise in Judicial Review and Constitutional law in line with the progressive nature of the Constitution of Kenya 2010. 
                                            </p>
                                        </Link>
                                    </div>
                                    <div className="services-content-sample services-content-sample-6 commercial-pest-control-services-content">
                                        <Link to='/alternative-dispute-resolution'>
                                            <h2>
                                                Alternative Dispute Resolution 
                                            </h2>
                                            <p>
                                                We have a skilled team with professional training on Alternative Dispute Resolution. We represent clients in all avenues of Alternative Dispute Resolution including Arbitration.
                                            </p>
                                        </Link>
                                    </div>
                                    <div className="services-content-sample services-content-sample-7">
                                        <Link to='/conveyancing-real-estate'>
                                            <h2>
                                                Conveyancing and Real Estate 
                                            </h2>
                                            <p>
                                                In conveyancing and real estate, we undertake all forms of real estate transactions representing both individuals and corporates such as banks. 
                                            </p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </m.div>
    )
}

export default PracticeAreas