import { Link } from "react-router-dom";
import { useEffect } from "react";
import { FaAward } from "react-icons/fa";
import { GiIonicColumn } from "react-icons/gi";
import { MdOutlineHandshake } from "react-icons/md";
import '../pages/home/home.css';

const TeamAreaIntro = () => {

    return (
        <div className="cta team-cont-intro">
            <div className="container cta-container cta_cont_practice_area team-header-cont">
                <div className="cta-text-l">
                    <h2>Our Team</h2> 
                </div>
                <div className="cta-text-r">
                    <div className="value value_practice_area">
                        <p>
                            We work with highly qualified and dynamic professionals and have a strong emphasis on quality and excellence. The firm is made up of highly experienced legal professionals whose expertise spans diverse areas of legal practice. Our team is well equipped with requisite skills and tools consistent with the client’s needs. Moreover, the team undergoes continuous professional development, improving work efficiency and keeping abreast with legal developments. Each staff member plays a complementary role in establishing a highly effective and efficient team. Our team includes;
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TeamAreaIntro