
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NavBar from './components/NavBar';
import Home from './pages/home/Home';
import About from './pages/about/About';
import PracticeAreas from './pages/practice-areas/PracticeAreas';
import LitigationDisputeResolution from './pages/litigation-dispute-resolution/LitigationDisputeResolution';
import FamilyLawSuccession from './pages/family-law-succession/FamilyLawSuccessio';
import CommercialCorporateLaw from './pages/commercial-corporate-law/CommercialCorporateLaw';
import ConveyancingRealEstate from './pages/conveyancing-real-estate/ConveyancingRealEstate';
import CriminalLaw from './pages/criminal-law/CriminalLaw';
import JudicialReviewConstitutionalLaw from './pages/judicial-review-constitutional-law/JudicialReviewConstitutionalLaw';
import AlternativeDisputeResolution from './pages/alternative-dispute-resolution/AlternativeDisputeResolution';
import LitigationDisputeResolutionDepartment from './pages/litigation-dispute-resolution-department/LitigationDisputeResolutionDepartment';
import CorporateCommercialDepartment from './pages/corporate-commercial-department/CorporateCommercialDepartment';
import ConveyancingRealEstateDepartment from './pages/conveyancing-real-estate-department/ConveyancingRealEstateDepartment';
import LegalAdvisoryConsultancyDepartment from './pages/legal-advisory-consultancy-department/LegalAdvisoryConsultancyDepartment';
import Team from './pages/team/Team';
import Contact from './pages/contact/Contact';
import Footer from './components/Footer';


const App = () =>  {
  return (
    <BrowserRouter>
      <NavBar/>
        <Routes>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="practice-areas" element={<PracticeAreas/>} />
          <Route path="team" element={<Team/>} />
          <Route path="contact" element={<Contact />} />
          <Route path="litigation-dispute-resolution" element={<LitigationDisputeResolution/>} />
          <Route path="family-law-succession" element={<FamilyLawSuccession/>} />
          <Route path="commercial-corporate-law" element={<CommercialCorporateLaw/>} />
          <Route path="conveyancing-real-estate" element={<ConveyancingRealEstate/>} />
          <Route path="criminal-law" element={<CriminalLaw/>} />
          <Route path="judicial-review-constitutional-law" element={<JudicialReviewConstitutionalLaw/>} />
          <Route path="alternative-dispute-resolution" element={<AlternativeDisputeResolution/>} />
          <Route path='litigation-dispute-resolution-department' element={<LitigationDisputeResolutionDepartment/>}/>
          <Route path='corporate-commercial-department' element={<CorporateCommercialDepartment/>}/>
          <Route path='conveyancing-real-estate-department' element={<ConveyancingRealEstateDepartment/>}/>
          <Route path='legal-advisory-consultancy-department' element={<LegalAdvisoryConsultancyDepartment/>}/>
        </Routes>
      <NavBar/>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
