import {motion as m} from 'framer-motion';
import ScrollToTop from '../../components/ScrollToTop';
// import {useEffect} from 'react';
import PageHeaderImage from '../../images/pageheader.jpg';
import SanitationPageHeader from '../../components/SanitationPageHeader';
import {Link} from 'react-router-dom';
import WhyUs from '../../components/WhyUs';
import ContactCta from '../../components/ContactCta';
import FAQS from '../../components/FAQS';
import './familylawsuccession.css';

const FamilyLawSuccession = () => {
    return (
        <m.div 
            initial={{x: "-100%"}}
            animate={{x: "-0%"}}
            transition={{ duration: 0.9, delay:0.7}}
            exit={{opacity: 1}}
        >
            <ScrollToTop />
            <SanitationPageHeader title="Family law and Succession" image={PageHeaderImage}>
                <p>
                    <Link to="/">Home</Link> / <Link to="/services">Practice Ares</Link> / Family law and Succession 
                </p>
            </SanitationPageHeader>
            <section className='services'>

                <div className="sanitation-services">
                    <div className="container sanitation-services-content-container">
                        <div className="sanitation-services-content">
                            <h1>
                                Family Law and Succession
                            </h1>
                            <br />
                            <p>
                                We advise a diverse number of our clients on issues of family, inheritance and succession.  We deliver hands-on and personal guidance on family and succession issues. We also handle court succession disputes whenever any of them arise.
                                Our services in this area include:
                            </p>
                            <br />
                            <ul>
                                <li>
                                •	Adoption, guardianship and custody
                                </li>
                                <li>
                                •	Divorce
                                </li>
                                <li>
                                •	Probate and Estate administration
                                </li>
                                <li>
                                •	Advising clients on and helping them set up private trusts to hold and manage their estates.
                                </li>
                                <li>
                                •	Drafting wills, Trusts and Powers of Attorney
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="container sanitation-services-saftey-cont">
                    <ContactCta />
                </div>
            </section>
        </m.div>
    )
}

export default FamilyLawSuccession