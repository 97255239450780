import { useEffect } from 'react';
import AwesomeSlider from 'react-awesome-slider';
// import { heroMotionEffect } from '../heroMotionEffect';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import ImageOne from '../images/hero1.jpg';
import ImageTwo from '../images/hero2.jpg';
import ImageThree from '../images/hero3.jpg';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import {motion as m} from 'framer-motion';
import '../pages/home/home.css';

const AutoplaySlider = withAutoplay(AwesomeSlider);

const Homehero = () =>{

    // useEffect(()=>{
    //     heroMotionEffect('HeroText');
    // });

    return (
        <>
            <div className="home-hero">
                <AutoplaySlider
                    play={true}
                    cancelOnInteraction={false} // should stop playing on user interaction
                    interval={6000}
                >
                    <div className='hero-section'>
                            <img src={ImageOne} alt="" className='hero-image' />
                            <div data-aos='fade-up' id='HeroText' className="hero-text">
                                <div className="hero-text-detail">
                                    <h1>Trusted</h1>
                                    <h3>Law Firm</h3>
                                    <p style={{fontWeight:'100'}}> 
                                        Our Law firm prides itself in being a trusted legal partner properly placed to meet clients’ needs and offer timely solutions to legal problems.
                                    </p>
                                </div>
                            </div>
                            <Link data-aos='fade-up' to='/about' className='btn sm contact-cta-btn hero-btn cta-btn-hero'>Read More</Link>
                            <div className="image-overlay"></div>
                    </div>

                    <div className='hero-section'>
                            <img src={ImageTwo} alt="" className='hero-image' />
                            <div  data-aos='fade-up' id='HeroText' className="hero-text">
                                <div className="hero-text-detail">
                                    <h1>Let's</h1>
                                    <h3>Face Today</h3>
                                    <p style={{fontWeight:'100'}}> 
                                        Empowering You to Face Today's Legal Challenges: We prioritize your needs above all else, meticulously crafting personalized strategies tailored to safeguard your interests and guide you through every legal endeavor.
                                    </p>
                                </div>
                            </div>
                            <Link data-aos='fade-up' to='/contact' className='btn sm contact-cta-btn hero-btn cta-btn-hero'>Read More</Link>
                            <div className="image-overlay"></div>
                    </div>
    
                    <div className='hero-section'>
                            <img src={ImageThree} alt="" className='hero-image' />
                            <div data-aos='fade-up' id='HeroText' className="hero-text">
                                <div className="hero-text-detail">
                                    <h1>Dedicated</h1>
                                    <h3>Law Services</h3>
                                    <p style={{fontWeight:'100'}}> 
                                        At Shisanya & Company Advocates, we have a highly experienced, knowledgeable and skillful youthful team of human resource hence the firm's success under the leadership of its Managing Partner.    
                                    </p>
                                </div>
                            </div>
                            <Link  data-aos='fade-up' to='/practice-areas' className='btn sm contact-cta-btn hero-btn cta-btn-hero'>Read More</Link>
                            <div className="image-overlay"></div>
                    </div>

                </AutoplaySlider>
            </div>
        </>
    );
}


export default Homehero