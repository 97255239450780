import {motion as m} from 'framer-motion';
import ScrollToTop from '../../components/ScrollToTop';
import {useEffect} from 'react';
import PageHeaderImage from '../../images/pageheader.jpg';
import PageHeader from '../../components/PageHeader';
import {Link} from 'react-router-dom';
// import HomeServices from '../../components/HomeServices';
import WhyUs from '../../components/WhyUs';
import ContactCta from '../../components/ContactCta';
import FAQS from '../../components/FAQS';
import './conveyancingrealestate.css';

const ConveyancingRealEstate = () => {
    return (
        <m.div 
            initial={{x: "-100%"}}
            animate={{x: "-0%"}}
            transition={{ duration: 0.9, delay:0.7}}
            exit={{opacity: 1}}
        >
            <ScrollToTop />
            <PageHeader title="Conveyancing and Real Estate" image={PageHeaderImage}>
                <p>
                    <Link to="/">Home</Link> / <Link to="/services">Practice Areas</Link> / Conveyancing and Real Estate 
                </p>
            </PageHeader>
            <section className='services'>

                <div className="bird-control-container container">
                    <div className="bird-control-content-container">
                        <div className="bird-control-content">
                            <h1>
                                Conveyancing and Real Estate
                            </h1>
                            <br />
                            <p>
                                In conveyancing and real estate, we undertake all forms of real estate transactions representing both individuals and corporates such as banks. 
                                Some of our experience in Conveyancing and Real Estate is in the following areas:
                            </p>
                            <br/>
                            <ul>
                                <li>
                                •	Working with borrowers, guarantors and financiers on project loans and financing.
                                </li>
                                <li>
                                •	Litigating on adverse possession, illegal and fraudulent transfers and charges.
                                </li>
                                <li>
                                •	Advising clients on land use and planning.
                                </li>
                                <li>
                                •	Preparation and negotiating property contracts and transfers.
                                </li>
                                <li>
                                •	Preparation of charges, discharge of charges, leases, transfers and other instruments of conveyance.
                                </li>
                                <li>
                                •	Processing of titles.
                                </li>
                                <li>
                                •	Subdivision and amalgamation
                                </li>
                                <li>
                                •	Landlord-tenancy matters
                                </li>
                                <li>
                                •	Application for and lodging caveats, cautions and prohibitions.
                                </li>
                                <li>
                                •	Generally advising client on all aspects of land and property law.
                                </li>
                                <li>
                                •	Obtaining necessary consents for land transactions and development
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="container bird-control-saftey-cont">
                    <ContactCta />
                </div>
            </section>
        </m.div>
    )
}

export default ConveyancingRealEstate