import {motion as m} from 'framer-motion';
import ScrollToTop from '../../components/ScrollToTop';
import {useEffect} from 'react';
import PageHeaderImage from '../../images/pageheader.jpg';
import PageHeader from '../../components/PageHeader';
import TeamAreaIntro from '../../components/TeamAreaIntro';
import TeamCarousel from '../../components/TeamCarousel';
import ConsultantCarousel from '../../components/consultantCarousel';
import {Link} from 'react-router-dom';
import './team.css';

const Team = () => {
    return (
        <m.div 
            initial={{x: "-100%"}}
            animate={{x: "-0%"}}
            transition={{ duration: 0.9, delay:0.7}}
            exit={{opacity: 1}}
        >
            <ScrollToTop />
            <PageHeader title="Advocates" image={PageHeaderImage}>
                <p>
                    <Link to="/">Home</Link> / Advocates
                </p>
            </PageHeader>
            <TeamAreaIntro/>
            <TeamCarousel/>
            <div className="consultants-cont">
                <h3>Consultants </h3>
                <p>Shisanya & Company Advocates has a number of Consultants who include:</p>
            </div>
            <br/>
            <ConsultantCarousel />
        </m.div>
    )
}

export default Team