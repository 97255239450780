import { Link } from "react-router-dom";
import { useEffect } from "react";
import { FaAward } from "react-icons/fa";
import { GiIonicColumn } from "react-icons/gi";
import { MdOutlineHandshake } from "react-icons/md";
import { GiGearHammer } from "react-icons/gi";
import '../pages/home/home.css';

const CTA = () => {

    return (
        <div className="cta">
            <div className="container cta-container">
                <div className="cta-text-l">
                    <h2>Trusted law firm for any sector.</h2> 
                </div>
                <div className="cta-text-r">
                    <div className="value">
                        <div className="value-cont">
                            <div className="value-icon"><FaAward className="value-icon"/></div>
                            <div className="value-text">
                                <h3>Client Focus</h3>
                                <p>We prioritize your needs, crafting strategies tailored to safeguard your interests.</p>
                            </div>
                        </div>
                    </div>
                    <div className="value">
                        <div className="value-cont">
                            <div className="value-icon"><GiIonicColumn className="value-icon"/></div>
                            <div className="value-text">
                                <h3>Accountability</h3>
                                <p>Trust us to deliver on our promises, with a commitment to reliability every step of the way.</p>
                            </div>
                        </div>
                    </div>
                    <div className="value">
                        <div className="value-cont">
                            <div className="value-icon"><MdOutlineHandshake className="value-icon"/></div>
                            <div className="value-text">
                                <h3>Transparency</h3>
                                <p>Depend on us to furnish clear, concise information, to guide your decisions confidently.</p>
                            </div>
                        </div>
                    </div>
                    <div className="value">
                        <div className="value-cont">
                            <div className="value-icon"><GiGearHammer className="value-icon"/></div>
                            <div className="value-text">
                                <h3>Effectiveness</h3>
                                <p>Trust our proactive and strategic approach to deliver optimal outcomes for your legal needs.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CTA