import {useState} from 'react';
import {testimonials} from '../data';
import TestimonialImage from '../images/testimonial-two.png';
import Card from '../UI/Card';
import {FaQuoteLeft} from 'react-icons/fa';
import {FaQuoteRight} from 'react-icons/fa';
import {GrNext} from 'react-icons/gr';
import {GrPrevious} from 'react-icons/gr';
import '../pages/home/home.css';

const Testimonial = () => {

    const [index, setIndex] = useState(0);
    const {name, description, title, img} = testimonials[index];


    const prevSlide = () => {
        setIndex(prev => prev - 1);
        if(index === 0){
            setIndex(testimonials.length - 1);
        }
    };


    const nextSlide = () => {
        setIndex(prev => prev + 1);
        if(index === testimonials.length - 1){
            setIndex(0);
        }
    };

    return (
        <div className='testimonials'>
            <div className="cont testimonials_cont">
                <div className="testimonial_l">
                    <img src={TestimonialImage} alt="" />
                </div>
                <div className="testimonial_r">
                    <div className="testimonials_header">
                        <h3>TESTIMONIALS</h3>
                        <h1>What customers say about us.</h1>
                        <p>Ready to join our list of happy clients? Contact us today for a consultation!</p>
                    </div>
                    <div className='testimonial'>
                        <Card className='testimonial_card'>
                            <p className='testimonial_desc'>
                                <FaQuoteLeft className='quote_left' /> 
                                {`${description}`}
                                <FaQuoteRight className='quote_right' />
                            </p>
                            <div className="testimonial_avatar">
                                <div className='testimonial_img'>
                                    <img src={img} alt="testimonial person image" />
                                </div>
                                <div className="testimonial_bio">
                                    <h3 className='testimonial_name'>{name}</h3>
                                    <h4 className='testimonial_title'>{title}</h4>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className='testimonial_arrows'>
                            <button className='testimonial_arrow' onClick={prevSlide}><GrPrevious /></button>
                            <button className='testimonial_arrow' onClick={nextSlide}><GrNext /></button>
                        </div>
                </div>
            </div>
        </div>
    )
}

export default Testimonial