import {motion as m} from 'framer-motion';
import ScrollToTop from '../../components/ScrollToTop';
import {useEffect} from 'react';
import PageHeaderImage from '../../images/pageheader.jpg';
import PageHeader from '../../components/PageHeader';
import {Link} from 'react-router-dom';
import WhyUs from '../../components/WhyUs';
import ContactCta from '../../components/ContactCta';
import FAQS from '../../components/FAQS';
import './corporate-commercial-department.css';

const CorporateCommercialDepartment = () => {
    return (
        <m.div 
            initial={{x: "-100%"}}
            animate={{x: "-0%"}}
            transition={{ duration: 0.9, delay:0.7}}
            exit={{opacity: 1}}
        >
            <ScrollToTop />
            <PageHeader title="Litigation and Dispute Resolution" image={PageHeaderImage}>
                <p>
                    <Link to="/">Home</Link> / <Link to="/services">Depertments</Link> / Corporate Commercial Department
                </p>
            </PageHeader>
            <section className='services'>

                <div className="commercial-pest-control">
                    <div className="container commercial-pest-control-contcontent-container">
                        <div className="commercial-pest-control-content">
                            <h1>
                                Corporate Commercial Department
                            </h1>
                            <br />
                                <p>
                                    This is the department of the firm that provides a host of corporate advice to business and corporates both locally, regionally and internationally. The advisory services in this department ranges from equity investments and joint ventures, public offerings, share issues, mergers, acquisitions and disposals, corporate restructurings, management buy-outs, structured and trade finance among others. 
                                </p>

                                <p>
                                    The firm also advises banks, financial institutions and borrowers on all kinds of banking and financial transactions including corporate finance, project financing and loan syndications which in turn have exposed the firm to a selective clientele and a variety of commercial and corporate transactions. 
                                </p>

                        </div>
                    </div>
                </div>

                <div className="container commercial-pest-control-saftey-cont">
                    <ContactCta />
                </div>
            </section>
        </m.div>
    )
}

export default CorporateCommercialDepartment