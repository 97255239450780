import {IoLocation} from 'react-icons/io5';
import { FaAward } from "react-icons/fa";
import { GiIonicColumn } from "react-icons/gi";
import { MdOutlineHandshake } from "react-icons/md";
import { GiGearHammer } from "react-icons/gi";
import '../pages/home/home.css';
import { Link } from 'react-router-dom';

const WhyUs = () => {

    return (
        <div className='whyus'>
            <div className='whyus__container'>
                <div className='whyus_l'>
                    <h3>WHY CHOOSE US</h3>
                    <h1>The legal expert you can trust.</h1>
                    <p>
                        Established in 2020, Shisanya and Company advocates is Africa's fast-growing premier law firm located in Nairobi, Kenya. The firm prides itself in being a trusted legal partner properly placed to meet clients' needs and offer timely solutions to legal problems. 
                    </p>
                    {/* <Link to='/about'><button className='btn sm whyus_btn'>Discover More</button></Link> */}
                </div>
                <div className="whyus_r">
                    <div className='why_r_det'>
                        <FaAward className='why_r_icon' />
                        <h2 style={{color:'black'}}>Client Focus</h2>
                        <p style={{color:'white'}}>
                            We prioritize your needs, crafting strategies tailored to safeguard your interests. 
                        </p>
                    </div>
                    <div className='why_r_det'>
                        <GiIonicColumn className='why_r_icon' />
                        <h2 style={{color:'black'}}>Accountability</h2>
                        <p style={{color:'white'}}>
                            Trust us to deliver on our promises, with a commitment to reliability every step of the way.
                        </p>
                    </div>
                    <div className='why_r_det'>
                        <MdOutlineHandshake className='why_r_icon' />
                        <h2 style={{color:'black'}}>Transparency</h2>
                        <p style={{color:'white'}}>
                            Depend on us to furnish clear, concise information, to guide your decisions confidently.
                        </p>
                    </div>
                    <div className='why_r_det'>
                        <GiGearHammer className='why_r_icon' />
                        <h2 style={{color:'black'}}>Effectiveness</h2>
                        <p style={{color:'white'}}>Trust our proactive and strategic approach to deliver optimal outcomes for your legal needs.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhyUs