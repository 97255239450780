import React from 'react';
import '../pages/home/home.css';

const Homeintro = () => {
    return (
        <>
            <div className="home-about-sect">
                <h4>Dedicated Service</h4>
                <p>At Shisanya & Company Advocates, we have a highly experienced, knowledgeable and skillful youthful team of human resource hence the firm's success under the leadership of its Managing Partner.  Importantly, each staff member plays a complementary role in establishing a highly effective and efficient team.</p>
            </div>
        </>
    )
}

export default Homeintro