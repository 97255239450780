import CommentIcon from '../images/whatsapp.png';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import "./whatsappus.css";

const WhatsAppUs = () => {

    return (
        <>
            <span className="icon">
                <a href='https://wa.link/wmyge5' target="_blank">
                    <img src={CommentIcon} alt="comment icon" />
                </a>
            </span>
        </>
    )
}

export default WhatsAppUs